import UserChat from '../user-chat';
import '../user-chat/user-chat.css';
import { animateScroll } from "react-scroll";

export default class ContentChat extends UserChat{

    state = {
        messages: [],
        channelId: '',
        webhookSid: '',
        channel: null,
        activeInput: false,
        inputPlaceholder: 'type your message...',
        isActiveBackButton: false,
        isLoading: false,
        showWidget: false,
        // chatConfig: {},
      }

    componentDidMount = () => {
        // console.log('config from content-chat props', this.props.chatConfig);
        console.log('dev mode -',this.isDevMode);
        if(this.props.chatType !== 'content'){
            this.loadUserChat();
        }
        else{
            this.loadUserChat();
            const compContext = this;
            var elements = document.getElementsByClassName("open-content-chat");
          
            var downloadResourceListFull = []
            
            for (var i = 0; i < elements.length; i++) {

                elements[i].addEventListener('click', function(e){
                    e.preventDefault();
                    compContext.props.openChat();

                    if(compContext.state.sourceLink !== e.target.dataset.sourceLink){
                           
                        var downloadResourceList = downloadResourceListFull.filter(function( downloadResourceItem ) {
                            return downloadResourceItem.sourceLink !== e.target.dataset.sourceLink;
                        });

                        compContext.setState({
                            sourceTitle: e.target.dataset.sourceTitle,
                            sourceLink: e.target.dataset.sourceLink,
                            showWidget: true,
                            downloadResourceList : downloadResourceList
                        })                       
                       
                        compContext.setState({isLoading:true});
                    
                        compContext
                        .finishFlowExecution('content')
                        .then(res => {
                            compContext.setState({isLoading:false});
                            compContext.startDownloadFlow();
                            console.log(res);
                        })
                        .catch((error) => {
                            console.log(error);
                            compContext.setState({isLoading:false});
                            compContext.addMessage({ body: `Error: ${error.message}` })
                        })
                    }              
                    
                }, false);
                    
                var downloadResourceItem = {
                    sourceTitle : elements[i].dataset.sourceTitle,
                    sourceLink : elements[i].dataset.sourceLink
                };
                downloadResourceListFull.push(downloadResourceItem);
            }

                  
        }

    }

    // resetFlow = () => {
    //     console.log('test reset');
    //     console.log(this.finishFlowExecution);
    //     const res = this.finishFlowExecution();
    //     console.log(res);
    //     // this.finishFlowExecution
    //     //     .then(res => console.log(res));
    // }
    
    
    startDownloadFlow = () => {
        // this.handleNewMessage({body: 'system_new_flow'});
        this.handleNewMessage({body: '{"type":"system", "body":"system_new_flow"}'});
    }

    addMessage = (message) => {
        if(typeof(message.sid) === 'undefined' || message.sid === null){
            message.sid = this.utilsService.gaenerateChannelId(15);
        }
        message.messageDate = message.messageDate ? message.messageDate : new Date();

        //display message only if it's not system msg        
        if(message.type !== 'system'){
            //replace main source
            message.body = message.body.replace('[name_of_downloadable_resource]', `<a target='_blank' href='${this.state.sourceLink}'>${this.state.sourceTitle}</a>`);
            
            //replace resources list
            if(this.state.downloadResourceList){                
                if(this.state.downloadResourceList.length == 0 && message.body.includes("[list_of_downloadable_resources]")){
                    var emptyResourceMessage = "Sorry, we have not another resources on this page.";
                    message.body = emptyResourceMessage;
                }
                else{
                    var resourcesListStr = `${this.state.downloadResourceList.map(resource=>`<a target='_blank' href='${resource.sourceLink}'>${resource.sourceTitle}</a>`).join('<br/>')}`;
                    message.body = message.body.replace('[list_of_downloadable_resources]', resourcesListStr); 
                }
            }

            //replace other links
            // console.log('replace links', this.props.chatConfig.replaceLinks);
            this.props.chatConfig.replaceLinks.forEach(link => {
                message.body = message.body.replace(link.placeholder, `<a target='_blank' href='${link.url}'>${link.title}</a>`);
            });

            this.setState({
              messages: [...this.state.messages, message],
            })
            animateScroll.scrollToBottom({
                containerId: "messages",
                duration: 100
            });
        }
        

      }

} 