import React from 'react';
import ChatButton from '../chat-button';
import './buttons-list.css';

function ButtonsList(props) {
    const {messageSid, buttons, onButtonClick} = props;
    const buttonsElements = buttons.map((button, index) => {
        
        return <ChatButton key={index} 
                    label={button.label}
                    value={button.value}
                    type={button.type}
                    messageSid={messageSid}
                    onButtonClick={onButtonClick}/>
    })

    return (
        <div className="buttons-container">
            {buttonsElements}
        </div>
    )
}

export default ButtonsList;
