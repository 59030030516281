export default class ChatInteractionService {
    
    isDevMode = true;
    openChatTimeOutObjects = [];

    constructor(isDevMode = true){
        this.isDevMode = isDevMode;
    }


    startChatOnClick(chatContext, startChatClass = 'open-chat-by-click'){

        document.addEventListener('click',function(e){

            if(e.target && e.target.classList.contains(startChatClass)){
                e.preventDefault();
                const openClickChatType = e.target.dataset.chatType;
                let showBackButton = e.target.dataset.showBackButton || false;
                showBackButton = showBackButton == "true";

                const chatStartPhrase = e.target.dataset.startWith || "system_new_flow";

                //if(chatContext.state.alreadyOpened === false){
                    chatContext.setState({showBackButton: showBackButton});

                    chatContext.props.openChat();
                    //if(this.state.chatType != openHoverChatType){
                        chatContext.changeChatType(openClickChatType, chatStartPhrase);
                    //}
                //}
             }
         });
    }

    addOpenChatOnHoverHandlers = (chatContext, openOnHoverClass = 'open-chat-by-hover') => {

        const openHoverElements = document.getElementsByClassName(openOnHoverClass);

        for (var k = 0; k < openHoverElements.length; k++) {
            if(this.isDevMode){
                console.log('Hover Chat Type: ', chatContext.props.chatType);
            }
            openHoverElements[k].addEventListener('mouseenter', (e)=>{
                e.preventDefault();
                const openHoverChatType = e.target.dataset.chatType;
                const chatStartPhrase = e.target.dataset.startWith || "system_new_flow";

                if(chatContext.state.alreadyOpened === false){
                    chatContext.setState({alreadyOpened:true});

                    chatContext.props.openChat();
                    //if(this.state.chatType != openHoverChatType){
                        chatContext.changeChatType(openHoverChatType, chatStartPhrase);
                    //}
                }
            }, false);
        }
    }

    addOpenChatOnVisibleTimeHandlers = (chatContext, openOnTimeViewClass = 'open-chat-by-time-view') => {
        const openTimeElements = document.getElementsByClassName("open-chat-by-time-view");

        for (var l = 0; l < openTimeElements.length; l++) {
            const openTimeElement = openTimeElements[l];
            const openTimeChatType = openTimeElement.dataset.chatType;
            const chatStartPhrase = openTimeElement.dataset.startWith || "system_new_flow";
            const openTimeElementIndex = l;

            window.addEventListener('scroll', ()=>{

                this.openChatByVisibleTime(openTimeElement, openTimeElementIndex, chatContext.props.chatConfig.openTimeOut, ()=> {
                    if(chatContext.state.alreadyOpened === false){
                        chatContext.setState({alreadyOpened:true});

                        chatContext.props.openChat();
                        if(chatContext.state.chatType != openTimeChatType){
                            chatContext.changeChatType(openTimeChatType, chatStartPhrase);
                        }
                    }
                });

              });
        }
    }

    openChatByVisibleTime = (target, index, timeout, callback) => {
        var targetPosition = {
            top: window.pageYOffset + target.getBoundingClientRect().top,
            bottom: window.pageYOffset + target.getBoundingClientRect().bottom
          },
          windowPosition = {
            top: window.pageYOffset,
            bottom: window.pageYOffset + document.documentElement.clientHeight
          };

        if (targetPosition.bottom > windowPosition.top &&
          targetPosition.top < windowPosition.bottom) {

          if(!this.openChatTimeOutObjects[index]){
            this.openChatTimeOutObjects[index]=setTimeout(()=>{
                callback();
            }, timeout);
          }

        } else {
            clearTimeout(this.openChatTimeOutObjects[index]);
            this.openChatTimeOutObjects[index]="";
        };
      };
};
