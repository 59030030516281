import React, { Component } from 'react';
import Timer from 'react-compound-timer'
import './voice-call.css';

export default class VoiceCall extends Component {
  
    render() { 

        const {onVoiceCallEnd} = this.props;

        return (
            <div className="voice-call-container">
                <Timer 
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}>
                    {() => (
                        <>
                            <div className="progress-call">
                                <div className="call-indicator">
                                    <div className="call-indicator-content">
                                        <div className="call-indicator-inner white-blue">
                                            <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/Call_vaxchora.png' />
                                        </div>
                                    </div>
                                </div>
                                <div className="call-timer"> 
                                    <Timer.Minutes/><span>:</span>
                                    <Timer.Seconds/> 
                                </div> 
                            </div>
                            <button className="button-hangup" 
                               href="#" 
                               onClick={ onVoiceCallEnd }>
                                <span className="call-indicator-inner white-red">
                                    <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/Call_vaxchora.png' />
                                </span>
                                <span className="button-label">End Call</span>
                            </button>
                        </>
                    )}                      
                </Timer>
            </div>
        )
    }
}