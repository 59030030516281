import React, { Component } from 'react';
import './chat-header.css';

export default class ChatHeader extends Component {

    // getChatHeaderTitle = (type) => {
    //     switch(type){
    //         case 'content': return 'Content Bot';
    //         case 'registration': return 'Registration Bot';
    //         case 'mirf': return 'MIRF Bot';
    //         default: return 'Ebsi Bot';
    //     }
    // }

    getChatHeaderTitle = () => 'Need more answers?';
    getChatHeaderDescription = () => 'There are lots of ways to connect.';


 

    buttonTypes = [
        'call',
        'text',
    ]
    render() {
        const {toggleChat, chatType, activeChatConnectType, isPhoneCalling, onConnectTypeChange} = this.props;
       
        const typeButtons = this.buttonTypes.map((type) => {
            return <button
                    key={type} 
                    className={`chat-connect-${type} ${activeChatConnectType==type?'active':''}`}
                    onClick={() => onConnectTypeChange(type)}>                              
                </button>
        })

        const phoneCallIndicator = () =>{
            return (
            <div className="phone-call-indicator">
                <div className="phone-call-indicator-content">
                    <div className="phone-call-indicator-inner">
                        <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/Call_vaxchora.png' />
                    </div>
                </div>
            </div>
            )
        }


        return (
            <div className="chat-header">
                <div className="chat-info">
                    <div className="chat-info-row">
                        <div className="chat-info-col">
                            <div className="chat-avatar-icon">
                                <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/big_avatar.png' alt='Avatar'></img>
                                {isPhoneCalling ? phoneCallIndicator() : null}                                
                            </div>
                        </div>
                        <div className="chat-info-col">
                            <div className="chat-title">{this.getChatHeaderTitle()}</div>
                            <div className="chat-description">{this.getChatHeaderDescription()}</div>
                        </div>
                        <div className="chat-info-col">
                            <div className='chat-control'>
                                <a href='/#' onClick={toggleChat}>&#10006;&#xFE0E;</a>
                            </div>
                        </div>
                    </div>
                    <div className="chat-connect">
                        {typeButtons}
                    </div>
                </div>
               
            </div>
        )
    }
}
