import React from 'react';
import './message-list.css';
import ChatMessage from '../chat-message';
import MessageTime from '../message-time';
// import botIcon from '../../../public/images/no-avatar.png';

function MessageList(props) {
    const {onMultiplySelectMenuClose, onLoadPreviousClick} = props;
    const elements = props.messages.map((message, index) => {
        const { sid, messageDate, onButtonClick, isUserMsg = false, showAvatar = true } = message;
        const authorClass = isUserMsg ? 'user' : 'bot';
        let containerClasses = `ebsi-chat-message-container message-${authorClass}`;
        if(!showAvatar && !isUserMsg){
            containerClasses += ' msg-queue';
        }
        const msgIcon = <div className="msg-icon">
                <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/big_avatar.png' alt="chat-bot"/>
            </div>

        return (
            <div key={sid} className={containerClasses}>
                {!isUserMsg && showAvatar? msgIcon : null}
                <ChatMessage message={message} onButtonClick={onButtonClick} onMultiplySelectMenuClose={onMultiplySelectMenuClose}/>
                {/* <MessageTime messageDate={messageDate}/> */}
            </div>
        )
    })

    return (
        <div className="message-list">
            {/* <a href="#" 
                className="previous-link"
                onClick={e => onLoadPreviousClick(e)}>Previous message</a> */}
            {elements}
        </div>
    )
}

export default MessageList
