export default class UtilsService {
    
    gaenerateChannelId = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     millisecondsToHmsDisplay = (d) => {
      d = Number(d);

      var dSeconds = d/1000;

      var h = Math.floor(dSeconds / 3600);
      var m = Math.floor(dSeconds % 3600 / 60);
      var s = Math.floor(dSeconds % 3600 % 60);
  
      var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
  }
};
