import React from 'react'
import './chat-button.css';

function ChatButton(props) {
    const { messageSid, label, value, type, onButtonClick } = props;
    
    const onButtonClickEventHandler = (e, messageSid, label, value) => {
        e.preventDefault();
        onButtonClick(messageSid, label, value);
    }

    return (
        type === 'link' 
            ? <a className='ebsi-chat-button' href={value} target='_blank' rel="noopener noreferrer">{label}</a>
            : <a className='ebsi-chat-button' href='/#' onClick={(e) => onButtonClickEventHandler(e, messageSid, label, value)}>{label}</a>
    )
}

export default ChatButton

