import React from 'react';
import './message-loader.css';

function MessageLoader() {
    return (
        <div className="message-loader">
            <div className="loader-container">
                {/* <div className="dash uno"></div>
                <div className="dash dos"></div>
                <div className="dash tres"></div>
                <div className="dash cuatro"></div> */}
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}

export default MessageLoader;