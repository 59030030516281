import React from 'react';
import './App.css';
import ChatWidget from '../chat-widget';
// import {
//   BrowserRouter as Router,
//   Route,
// } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* <Router>
        <Route path='/ebsi-chat/content/:sourceTitle/:sourceLink' component={ChatWidget} />
      </Router> */}
      <ChatWidget/>
    </div>
  );
}

export default App;
