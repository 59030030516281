import React, { Component } from 'react';
import './message-input.css';

export default class MessageInput extends Component {

    state = {
        messageText:''
    }

    textInput = React.createRef();

    componentDidUpdate() {  
        if(this.props.showChat === true){      
            this.textInput.current.focus();
        }
        this.setInputHeight();
    }

    onMessageChange = (e) => {
        this.setState({
            messageText: e.target.value
        })
        this.setInputHeight();
    }
    

    onInputKeyDown = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            this.checkAndSendMessage();
        }
    }

    checkAndSendMessage = () => {
        const messageText = this.state.messageText;

        if(messageText.trim().length > 0){
            this.addNewMessage(messageText);
            this.textInput.current.blur();
        }
    }

    onBackButtonClick = (e) => {
        e.preventDefault();
        this.addNewMessage('Back');
    }
    

    addNewMessage = (text) =>{
        const message = {
            body:text,
            isUserMsg: true
        }
        this.props.addMessage(message);
        this.setState({
            messageText: ''
        })
    }

    setInputHeight = () => {
        this.textInput.current.style.height = '';
        this.textInput.current.style.height = `${this.textInput.current.scrollHeight}px`;  
    }

    render() {
        const { placeholder, isActiveBackButton, activeInput, showBackButton } = this.props;
        const inputClassName = `message-input ${!activeInput ? 'disabled-input':''}`
        const backButtonClassName = `back-button ${!isActiveBackButton ? 'disabled-button':''}`
        return (
            <div className={inputClassName}>
                {showBackButton === true?
                    <div className="return-back-container">
                        <a href="#" onClick={(e) => this.onBackButtonClick(e)} disabled={!isActiveBackButton}>Back to previous question</a>
                    </div>
                    :""
                }
                <textarea id="chat-input" type="text"
                        placeholder={placeholder}
                        autoComplete='off'
                        disabled={!activeInput} 
                        value={ this.state.messageText }
                        autoFocus={false}
                        onKeyDown={this.onInputKeyDown}
                        onChange={this.onMessageChange}
                        ref={this.textInput}>
                </textarea>

                <button className="send-btn" onClick={this.checkAndSendMessage} disabled={!activeInput}>Send</button>
            </div>
        )
    }
}
