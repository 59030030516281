import React, { Component } from 'react'
import UserChat from '../user-chat';
import ContentChat from '../content-chat';
import ChatService from '../../services/chatService';
import * as config from '../../config';
import './chat-widget.css';
import WidgetIconsContainer from '../widget-icons-container';

export default class ChatWidget extends Component {

    chatService = new ChatService(config.API_BASE_URL);

    // state = {
    //     showWidget: true,
    //     type: 'registration',
    //     chatConfig: {}
    // }

    constructor(props) {
        super(props);
        // Не вызывайте здесь this.setState()!
        // const chatConfig = this.chatService.getRegistrationChatConfigMockup();
        // const chatConfig = this.chatService.getContentChatConfigMockup();
        // const chatConfig = this.chatService.getConversationalExchangeChatConfigMockup();
        // const chatConfig = this.chatService.getTransactionalExchangeChatConfigMockup();
        // const chatConfig = this.chatService.getMirfChatConfigMockup();
        // const chatConfig = this.chatService.getRetailVoucherChatConfigMockup();
        const chatConfig = window.ebsiChatConfig;
        this.state = {
            showChat: chatConfig.showChat, 
            type: chatConfig.type, 
            chatConfig,
            firstOpen: false,
            chatHasBeenConfigured: false
        };
    }

    toggleChat = (e) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                showChat: !state.showChat,
                firstOpen: true
            }
        })
    }

    openChat = () => {
        this.setState({showChat: true, firstOpen: true})
    }
    

    onChatHasBeenReady = (e) => {
        this.setState({chatHasBeenConfigured: true});   
    }

    // componentDidUpdate() {
    //     if(this.state.showWidget){
    //         document.body.style.backgroundColor = "#dfe1ed"
    //     }
    //     else{
    //         document.body.style.backgroundColor = "transparent"
    //     }
        
    // }
    
    render() {
        const { showChat, type, chatConfig, firstOpen, chatHasBeenConfigured } = this.state;
        return (
            <div className='chat-widget'>
                {type === 'content'
                    ? <ContentChat 
                        showChat={showChat}
                        openChat={this.openChat} 
                        toggleChat={this.toggleChat} 
                        chatType={this.state.type}
                        chatConfig={chatConfig}/>
                    :<UserChat 
                        showChat={showChat}
                        openChat={this.openChat} 
                        toggleChat={this.toggleChat}
                        onChatHasBeenReady={this.onChatHasBeenReady} 
                        chatType={this.state.type}
                        chatConfig={chatConfig}
                        firstOpen={firstOpen}/>
                    }
                {/* <WidgetIcon showChat={showChat} toggleChat={this.toggleChat}/> */}
                <WidgetIconsContainer showIcon={!showChat && chatHasBeenConfigured} toggleChat={this.toggleChat}/>
            </div>
        )
    }
}
