import React from 'react';
import './widget-icon.css';

function WidgetIcon(props) {
    const { showIcon, toggleChat, children, className} = props;
    const additionalClassName = `${className?className+' ':''}`;
    const iconClass = `${additionalClassName}widget-icon`
    return (
        <div className={iconClass}>
            <a href='/#' onClick={toggleChat}>
                {/* <div className="img-wrp">                
                    <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/big_avatar.png' alt='Open chat'></img>
                </div> */}
                {children}
            </a>
        </div>
    )
}

export default WidgetIcon
