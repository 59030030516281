// function MessageTime(props) {

//     isToday = (someDate) => {
//         const today = new Date()
//         return someDate.getDate() == today.getDate() &&
//           someDate.getMonth() == today.getMonth() &&
//           someDate.getFullYear() == today.getFullYear()
//     }

//     const { messageDate = new Date() } = props;
//     let msgTime = this.isToday(messageDate) ? messageDate.getTime() : messageDate.getDate();
//     return (
//         <div className="message-time">
//             <span>{msgTime}</span>
//         </div>
//     )
// }

import React, { Component } from 'react'
import './message-time.css';

export default class MessageTime extends Component {

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() === today.getDate() &&
          someDate.getMonth() === today.getMonth() &&
          someDate.getFullYear() === today.getFullYear()
    }

    render() {
        const { messageDate = new Date() } = this.props;
        let msgTime = this.isToday(messageDate) 
                        ? `${messageDate.getHours()}:${messageDate.getMinutes()}`
                        : `${messageDate.toLocaleString('en-us', { month: 'short' })} ${messageDate.getDate()}`;

        return (
            <div className="message-time">
                <span>{msgTime}</span>
            </div>
        )
    }
}

