import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import ChatService from '../../services/chatService';
import UtilsService from '../../services/utilsService';
import ChatInteractionService from '../../services/сhatInteractionService';
import TwilioChat from 'twilio-chat';
import MessageInput from '../message-input';
import MessageLoader from '../message-loader';
import * as config from '../../config';
import ChatHeader from '../chat-header';
import MessageList from '../message-list';
import { animateScroll } from "react-scroll";
import VoiceCall from '../voice-call';

import './user-chat.css';

const { Device } = require('twilio-client');

export default class UserChat extends Component {

    chatService = new ChatService(config.API_BASE_URL);
    utilsService = new UtilsService();
    cookies = new Cookies();
    isDevMode = process.env.REACT_APP_DEBUG_MESSAGES === 'TRUE';
    ipCallNumber = process.env.REACT_APP_IP_CALL_NUMBER || '12059645482';
    chatInteractionService = new ChatInteractionService(this.isDevMode);
   
    constructor(props){
        super(props);
        this.state = {
            messages: [],
            channelId: '',
            webhookSid: '',
            channel: null,
            activeInput: false,
            isActiveBackButton: false,
            isLoading: false,
            showWidget: true,
            firstMessageIndex: null,
            activeChatConnectType: 'text',
            chatType: this.props.chatType,
            alreadyOpened: false,
            showBackButton: this.props.showBackButton,
            isPhoneCalling: false,
            phoneDevice: {},
            callDateStarted: {},

            // chatConfig: {},
          }
    }



    componentDidMount = () => {
        console.log('dev mode -',this.isDevMode);
        // if(this.props.chatType != 'content'){
        //     this.loadUserChat();
        // }
        // else{

        // }
        // this.setState({chatConfig: this.props.chatConfig});
        // this.loadUserChat();
        // this.loadAndStartUserChat();
        this.loadUserChatNew();

        const componentContext = this;

        var mirfElements = document.getElementsByClassName("start-mirf-chat");
        var conversationalElements = document.getElementsByClassName("start-conversational-chat");
        var openHoverElements = document.getElementsByClassName("open-chat-by-hover");
        var openTimeElements = document.getElementsByClassName("open-chat-by-time-view");

        for (var i = 0; i < mirfElements.length; i++) {

            mirfElements[i].addEventListener('click', function(e){
                e.preventDefault();

                componentContext.changeChatType('mirf');

            }, false);
        }

        for (var j = 0; j < conversationalElements.length; j++) {

            conversationalElements[j].addEventListener('click', function(e){
                e.preventDefault();

                componentContext.changeChatType('conversationalExchange');

            }, false);
        }

        this.chatInteractionService.addOpenChatOnHoverHandlers(componentContext, 'open-chat-by-hover');
        this.chatInteractionService.addOpenChatOnVisibleTimeHandlers(componentContext, 'open-chat-by-time-view');
        this.chatInteractionService.startChatOnClick(componentContext, 'open-chat-by-click');

    }

    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.firstOpen === false && this.props.firstOpen === true){
            this.handleNewMessage({body: this.props.chatConfig.startPhrase || 'system_new_flow'});
        }
    }

    changeChatType = (type, startPhrase = 'system_new_flow') => {
        const previousChatType = this.state.chatType;

        if(previousChatType === type){
            console.log('same type');
            this.setState({
                isLoading:true
            });

            this.restarFlowExecution(startPhrase)
                .then(res => {
                    console.log('Restarted flow succesfully.');
                    animateScroll.scrollToBottom({
                        containerId: "messages",
                        duration: 500
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({isLoading:false});
                    //this.addMessage({ body: `Error: ${error.message}` })
                })
        }
        else{
            this.setState({
                chatType: type,
                isLoading:true
            });
    
            this.finishFlowExecution(previousChatType)
                .then(res => {
                    this.state.channel.removeAllListeners();
                })
                .then(res => {
    
                    this.loadAndStartUserChat(null, startPhrase);
                    // this.setState({isLoading:false});
                    animateScroll.scrollToBottom({
                        containerId: "messages",
                        duration: 500
                    });
                    console.log(res);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({isLoading:false});
                    this.addMessage({ body: `Error: ${error.message}` })
                })
        }

        
    }

    loadUserChat = (newChannel = false) => {
        this.getToken(newChannel)
        .then(this.createChatClient)
        // .then(this.createOrJoinGeneralChannel)
        .then(this.joinChannelAsync)
        .then(this.configureChannelEvents)
        .catch((error) => {
            console.log(error);
            this.addMessage({ body: `Error: ${error.message}` })
        })
    }

    loadUserChatNew = async (newChannel = false) =>{
        return this.getToken(newChannel)
                .then(this.createChatClient)
                // .then(this.createOrJoinGeneralChannel)
                .then(this.joinChannelAsync)
                .then(this.configureChannelEvents)
                .catch((error) => {
                    console.log(error);
                    this.addMessage({ body: `Error: ${error.message}` })
                })
    }

    loadAndStartUserChat = (newChannel = false, startPhrase = 'system_new_flow') => {
        this.loadUserChatNew(newChannel)
            .then(res => {
                this.handleNewMessage({body: startPhrase});
            });
        
    }

    getToken = async(newChannel = false) =>{
        const channelId = this.getChannelId(newChannel);
        this.setState({ channelId })
        return await this.chatService.getTokenByType(channelId, this.state.chatType);
    }

    createChatClient = async (tokenData) => {
        return await TwilioChat.create(tokenData.token);
    }

    joinChannelAsync = async(chatClient) => {
        try{
            this.setState({isLoading:true});
            const channel = await this.getChannelAsync(chatClient);
            this.setState({ channel });
            if(this.isDevMode){
                console.log('Joined channel', channel);
            }

            if(channel.channelState.status !== 'joined')
            {
                await channel.join();
            }

            // if(this.props.chatConfig.type !== 'content'){
            //     this.handleNewMessage({body: 'system_new_flow'});
            // }

            //this.addMessage({ body: `Joined channel as ${this.state.channelId}` });
            //this.addMessage({ type:'text', body: `Welcome to our chat!`, buttons:[{label:"Let's start!",value:"Let's start!", type: "message"}] });
            window.addEventListener('beforeunload', () => {
                console.log('Leave...');
                channel.leave()
            });
            return channel;
        }
        catch(err){
            console.log('Error on joinChannelAsync',err);
            throw new Error(err);
        }
        finally{            
            // this.setState({isLoading:false});
        }
    }

    getChannelAsync = async(chatClient) => {
        await chatClient.getSubscribedChannels();
        try{
            //this.addMessage({ body: `Joining channel ${this.state.channelId}`})
            const channel = await chatClient.getChannelByUniqueName(this.state.channelId);
            await this.finishFlowExecution(this.state.chatType);
            return channel;
        }
        catch(err){
            console.log('Error on getChannelAsync',err);
            return this.createChannelAsync(chatClient);
            //this.createGeneralChannel(chatClient);
        }
    }

    createChannelAsync = async(chatClient) => {
        try{
            if(this.isDevMode){
                this.addMessage({ body: `Creating ${this.state.channelId} channel...` });
            }

            // const channel = await chatClient.createChannel({ uniqueName: this.state.channelId, friendlyName: this.state.channelId });
            const newChannelSid = await this.chatService.createFlexChannelChannel(this.state.channelId, this.state.chatType);
            // const channel = await chatClient.getChannelByUniqueName(this.state.channelId);
            const channel = await chatClient.getChannelBySid(newChannelSid);
            const member = await this.chatService.addBotToChannel(channel.sid, this.state.chatType);
            if(this.isDevMode){
                console.log('Member', member);
            }

            // const hookResult = await this.chatService.addChannelWebhookForStudio(channel.sid, this.props.chatType);
            // if(this.isDevMode){
            //     console.log('hook', hookResult);
            //     this.setState({webhookSid: hookResult});
            // }

            return channel;
        }
        catch(err){
            console.log('Error on createChannelAsync',err);
            throw new Error(`Could not create ${this.state.channelId} channel.` + err)
        }
    }

    configureChannelEvents = (channel) => {
        if(this.isDevMode){
            console.log('configuring...', channel);
        }

        channel.on('messageAdded', (message) => {
            console.log(message);
            const { author, body, state: { sid, timestamp, index } } = message;
            if(this.state.firstMessageIndex === null){
                this.setState({firstMessageIndex: index});
            }

            const messageDate = new Date(Date.parse(timestamp));
            this.processMessage(sid, author, body, messageDate);
        })

        channel.on('updated', ({channel}) => {
            if(channel.channelState.attributes.status === 'INACTIVE'){
                console.log('channel updated',channel.channelState.attributes);
                this.showStartOverMessage(); 
            }
            
        })

        // if(this.isDevMode){
        //     channel.on('memberJoined', (member) => {
        //         this.addMessage({ body: `${member.identity} has joined the channel.` })
        //     })

        //     channel.on('memberLeft', (member) => {
        //         this.addMessage({ body: `${member.identity} has left the channel.` })
        //     })
        // }
        this.props.onChatHasBeenReady();
    }

     getChannelId = (newChannel = false) => {
      let channelId = this.cookies.get(`${this.state.chatType}ChatChannelId`);
      if(newChannel || !channelId || channelId.trim() === '' || channelId === null){
        channelId = this.utilsService.gaenerateChannelId(config.CHANNEL_SID_LENGTH);
        this.cookies.set(`${this.state.chatType}ChatChannelId`, channelId, { path: '/' });
      }
      return channelId;
     }

     finishFlowExecution = async (chatType) => {
        //  console.log('channel',this.state.channel, ' type', this.props.chatType);
         return await this.chatService.finishFlowExecution(this.state.channelId, chatType);
     }

     restarFlowExecution = async (startPhrase = 'system_new_flow', newChannel=false) => {         
        await this.finishFlowExecution(this.state.chatType);
        if(newChannel){
            this.loadAndStartUserChat();
        }
        else{
            this.handleNewMessage({body: startPhrase});
        }
     } 


    //  sleep = (milliseconds) => {
    //     const date = Date.now();
    //     let currentDate = null;
    //     do {
    //       currentDate = Date.now();
    //     } while (currentDate - date < milliseconds);
    //   }
    scrollMessageContainer = (duration) =>{
        animateScroll.scrollToBottom({
            containerId: "messages",
            duration
        });
    }
    timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep = async(fn, ...args) => {
        await this.timeout(2000);
        return fn(...args);
    }

     addMessage = (message) => {
        //const messageData = { ...message, me: message.author === this.state.username }
        if(typeof(message.sid) === 'undefined' || message.sid === null){
            message.sid = this.utilsService.gaenerateChannelId(15);
        }
        message.messageDate = message.messageDate ? message.messageDate : new Date();
        // message.messageDate = message.messageDate ? message.messageDate : new Date();
        if(message.type === "start_over"){
            message.onButtonClick = this.onStartOverButtonClick; 
        }
        else{
            message.onButtonClick = this.onMessageButtonClick;
        }

        if(message.type === 'segment_identity'){
            window.analytics.identify(message.email, message.segmentData);
        }
        else if(message.type === 'phone_call'){           
            this.loadVoiceChat(message.pinCode);
        }
        else if(!message.body.startsWith('system_')){

            if(message.delay){
                this.setState((state) => { return { isLoading: true }});
                
                setTimeout(() => {
                    this.setState((state) => { return { isLoading: !message.lastInQueue, messages: [...this.state.messages, message] }});
                    this.scrollMessageContainer(1000);
                },message.delay);
            }
            else{
                this.setState({
                    messages: [...this.state.messages, message],
                })
    
                let showLoading = false;
                if(message.isUserMsg === true || message.showLoading === true){
                    showLoading = true;
                }
                this.setState({isLoading:showLoading});
    
                this.scrollMessageContainer(1000);
            }

            
        }
    }

    showStartOverMessage = () => {
        this.addMessage({ type: "start_over", body:"Thanks for chatting with us! <br> If you have any more questions please reach out to us again.", isBodyHtml: true, buttons:[{label:'Start New Chat', value: 'system_new_flow', type:"Message"}], isUserMsg: false, delay: 1000, lastInQueue: true});
        // this.scrollMessageContainer(500);
    }


    handleNewMessage = ({body}) => {
        if (this.state.channel) {
            this.state.channel.sendMessage(body)
        }
        // this.setState({isLoading:true});
    }

    processMessage = (sid, author, body, messageDate) => {
        let activeInput = false;
        let isActiveBackButton = false;
        console.log('new msg', body)
        try{
            let twilioMsg = JSON.parse(`${body}`);
            // twilioMsg = this.chatService.getMessageChoiceMockup();
            activeInput =  (typeof(twilioMsg.buttons) !== 'undefined' && twilioMsg.buttons.length > 0)
                             ||(typeof(twilioMsg.options) !== 'undefined' && twilioMsg.options.length > 0)
                             ||(typeof(twilioMsg.endOfFlow) !== 'undefined' && twilioMsg.endOfFlow === true) ? false : true;
            isActiveBackButton = (twilioMsg.isActiveBackButton === true);
            this.addMessage({ ...twilioMsg, sid, messageDate, isUserMsg: author ===  this.state.channelId});
        }
        catch(ex){
            //console.log(`cannot parse message ${body} - `,ex);
            this.addMessage({ body, sid, messageDate, isUserMsg: author ===  this.state.channelId});
        }
        finally{
            this.setState((state)=>{
                return {activeInput,isActiveBackButton};
            })
        }

    }

    onStartOverButtonClick = (sid, title, value = '') => {
        this.clearMessage(sid);
        // this.restarFlowExecution("system_new_flow" , true);
        this.finishFlowExecution(this.state.chatType)
                .then(res => {
                    this.state.channel.removeAllListeners();
                })
                .then(res => {
    
                    this.loadAndStartUserChat(true);
                    // this.setState({isLoading:false});
                    animateScroll.scrollToBottom({
                        containerId: "messages",
                        duration: 500
                    });
                    console.log(res);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({isLoading:false});
                    this.addMessage({ body: `Error: ${error.message}` })
                })
    }

    onMessageButtonClick = (sid, title, value = '') => {

        this.clearMessage(sid);

        const buttonMsgBody = {
            body: title,
            value: value
        }
        console.log('button value-',buttonMsgBody);
        this.handleNewMessage({body: JSON.stringify(buttonMsgBody)});
    }

    onMultiplySelectMenuClose = (messageId, options) => {
        //console.log('opt -',options)
        if(options.length > 0){
            this.clearMessage(messageId);

            const selectedOptionsTitles = options.map(option => option.label);

            const buttonMsgBody = {
                body: selectedOptionsTitles.join(', '),
                value: options
            }
            console.log('options values - ',buttonMsgBody);
            this.handleNewMessage({body: JSON.stringify(buttonMsgBody)});
        }
    }

    onLoadPreviousClick = (e) => {
        e.preventDefault();
        this.state.channel.getMessages(10,this.state.firstMessageIndex).then((messages)=>{
            console.log(messages);

        })
    }

    clearMessage = (messageId) => {
        this.setState((state) => {
            const messages = state.messages.map((msg) => {
                if(msg.sid === messageId){
                    msg.buttons = null;
                    msg.options = null;
                }
                return msg;
            });

            return {messages}
        })
    }

    onConnectTypeChange = (connectType) => {
        this.setState({
            activeChatConnectType:connectType
        })            

        if(connectType === "call"){
            this.changeChatType("transactionalExchange");
            // this.finishFlowExecution(this.state.chatType);

            // this.generatePinForChannel(this.state.channelId)
            // .then((pinCodeData)=>{                
            //     this.loadVoiceChat(pinCodeData.pinCode);
            // })
            // .catch((error) => {
            //     console.log(error);
            //     this.addMessage({ body: `Error: ${error.message}` })
            // });
        }
       
    }

    loadVoiceChat = (pinCode, newChannel = false) => {  
     
        this.getToken(newChannel)
            .then((data) => {
                
                const device = new Device(data.token, {                
                    codecPreferences: ["opus", "pcmu"],                
                    fakeLocalDTMF: true,                
                    enableRingingState: true
                });
                
                device.on('ready', (device)=>{
                    console.log('ip call number', this.ipCallNumber);
                    var params = {
                        To: this.ipCallNumber
                    };
                    if(this.isDevMode){
                        console.log("Calling " + params.To + "...");
                    }                                     
                        
                    if (device) {
                        var outgoingConnection = device.connect(params);
                        outgoingConnection.on("ringing", function() {
                            console.log("Ringing...");
                        });
                    }
                });

                device.on('connect', connection => {

                    if(this.isDevMode){
                        console.log("Connect...");
                    } 
                    
                    this.setState({
                        isPhoneCalling:true,
                        callDateStarted: new Date()
                    });    

                    animateScroll.scrollToBottom({
                        containerId: "messages",
                        duration: 1000
                    });

                    setTimeout(
                        () => connection.sendDigits(pinCode.toString()), 
                        1000
                    );
                });

                device.on('disconnect', connection => {
                    if(this.isDevMode){
                        console.log("Disconnect..."); 
                    }     

                    const callDateStartedTimeFormat = new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(this.state.callDateStarted);
                    // const callDateStartedTimeFormat = "qwerty";
                    const callDateEnded = new Date();
                    const callDuration = callDateEnded - this.state.callDateStarted;
                    const callDurationTimeFormat = this.utilsService.millisecondsToHmsDisplay(callDuration);
                    // const callDurationTimeFormat = "123"
                    
                    this.setState({
                        isPhoneCalling:false
                    });    

                    const message = {      
                        type: "voice_message",             
                        body:`<p>Call Ended.</p><p>${callDurationTimeFormat}, ${callDateStartedTimeFormat}</p>`,
                        isBodyHtml: true, 
                        isUserMsg: false, 
                        showAvatar:false   
                    }                
                    
                    this.addMessage(message);
                    this.showStartOverMessage(); 
                });

                this.setState({
                    phoneDevice:device
                });

          })
          .catch(function(err) {
            console.log(err);           
          });   
    }

    onVoiceCallEnd=()=>{

        const phoneDevice = this.state.phoneDevice;
        if(this.isDevMode){
            console.log("Hanging up...");
        }

        if (phoneDevice) {
            phoneDevice.disconnectAll();
        }  
    }    

    render() {
        const {showChat, toggleChat, chatConfig} = this.props;
        const { messages, showWidget, activeInput, isActiveBackButton, isLoading, activeChatConnectType, chatType, showBackButton, isPhoneCalling } = this.state;
        const chatClass = `ebsi-user-chat${!showChat || !showWidget ? ' chat-close' : ' delay-open'}`;
        return (
            <div className={chatClass}>
                <ChatHeader
                    toggleChat={toggleChat}
                    chatType={chatType}
                    activeChatConnectType={activeChatConnectType}
                    isPhoneCalling={isPhoneCalling}
                    onConnectTypeChange={this.onConnectTypeChange}/>
                <div id="messages">
                    <MessageList
                        messages={messages}
                        // onButtonClick={this.onMessageButtonClick}
                        onMultiplySelectMenuClose={this.onMultiplySelectMenuClose}
                        onLoadPreviousClick={this.onLoadPreviousClick}/>
                    {isLoading ? <MessageLoader/> : null}
                    {isPhoneCalling? <VoiceCall onVoiceCallEnd={this.onVoiceCallEnd}/> : null}
                </div>
                {/* {activeInput ? <MessageInput placeholder={inputPlaceholder} addMessage={this.handleNewMessage} isActiveBackButton={isActiveBackButton}/> : null} */}
                <MessageInput activeInput={chatConfig.alwaysActiveInput === true ? true : activeInput} placeholder='Enter your queries here.' addMessage={this.handleNewMessage} isActiveBackButton={isActiveBackButton} showBackButton = {showBackButton} showChat={showChat}/>
            </div>
        )
    }
}
