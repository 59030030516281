import React from 'react';
import './chat-options.css';
import Select from 'react-select'
import { Component } from 'react';

export default class ChatOptions extends Component {

    state = {
        selectedOptions: []
    }

    onMultiplySelectChange = (selectedOptions) => {
        this.setState({selectedOptions});
    }

    render(){

        const {messageSid, options, onButtonClick, isMulti, onMultiplySelectMenuClose} = this.props;

        const singleSelet = <Select
            options={options}
            menuPlacement='top'
            onChange={(selectedOption) => onButtonClick(messageSid, selectedOption.label, selectedOption.value)}
         />
    
        const multiplySelect = <Select
            options={options}
            isMulti
            closeMenuOnSelect={false}
            menuPlacement='top'
            onMenuClose={() => onMultiplySelectMenuClose(messageSid, this.state.selectedOptions)}
            onChange={(selectedOptions) => this.onMultiplySelectChange(selectedOptions)}
            //onChange={(selectedOption) => onButtonClick(messageSid, selectedOption.label, selectedOption.value)}
         />

        return (
            <div className='chat-options'>
                {isMulti ? multiplySelect : singleSelet}
            </div>
        )
    }

}
