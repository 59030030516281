import React, { Component } from 'react'
import ButtonsList from '../buttons-list';
import ChatOptions from '../chat-options';

export default class ChatMessage extends Component {
    
    render() {
        const { sid, body, type, isBodyHtml = false, buttons = null, options = null, isMulti = false } = this.props.message;
        const msgText = !isBodyHtml 
                        ? <span className="msg-text">{body}</span> 
                        : <span className="msg-text" dangerouslySetInnerHTML={{__html: body}}></span>
             
        const typeClass= (type != undefined && type == 'voice_message') ? 'voice-bubble':'';
              
        return (
            <div className="chat-message ">
                <div className={`message-bubble ${typeClass}`}>
                    {msgText}
                    {options != null ? <ChatOptions 
                                        options={options}
                                        messageSid={sid}
                                        onButtonClick={this.props.onButtonClick}
                                        onMultiplySelectMenuClose={this.props.onMultiplySelectMenuClose}
                                        isMulti={isMulti}
                                        /> : null}
                </div>
                {buttons != null ? <ButtonsList buttons={buttons} messageSid={sid} onButtonClick={this.props.onButtonClick}/> : null}
                
            </div>
        )
    }
}
