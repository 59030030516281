import React from 'react';
import WidgetIcon from '../widget-icon';
import './widget-icons-container.css';

function WidgetIconsContainer(props) {
    const { showIcon, toggleChat } = props;
    const iconsContainerClass = `widget-icons-container ${!showIcon ? 'icons-close' : 'icons-open'}`;
    return (
        <div className={iconsContainerClass}>
            <WidgetIcon showIcon={showIcon} toggleChat={toggleChat}>
                <div className="img-wrp">                
                    <img src='https://chat-app.emergenttravelhealth.com/images/png/1x/big_avatar.png' alt='Open chat'></img>
                </div>
            </WidgetIcon>   
            <WidgetIcon className="question-icon" showIcon={showIcon} toggleChat={toggleChat}>
                <div className="icon-text-card">
                    <span className="widget-icon-title">Questions?</span>
                    <span className="widget-icon-text">Get help now</span>
                </div>
            </WidgetIcon>          
        </div>
    )
}

export default WidgetIconsContainer
